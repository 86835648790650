import { React, useState } from 'react';
import { Link } from 'react-router-dom';
export default function MobileNav(props) {
    const [showDD, setShowDD] = useState({ opacity: '0', height: '0' })
    const [openMNav, setOpenMNav] = useState(false)
    const handleMouseClick = () => {
        if (showDD.height == '0') { setShowDD({ opacity: '1', height: '620px' }); setOpenMNav(true); return; }
        setOpenMNav(false);
        setShowDD({ opacity: '0', height: '0' })

    };
    return (

        <>
            <a href="#" className='font-bold ' onClick={handleMouseClick}><li className='flex justify-between'>{props.link}
                {openMNav ? <svg width="17" height="17" viewBox="0 0 45 45" fill="red" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.5 0C18.0499 0 13.6998 1.3196 9.99968 3.79193C6.29957 6.26426 3.41569 9.77828 1.71272 13.8896C0.00974882 18.001 -0.435826 22.525 0.432341 26.8895C1.30051 31.2541 3.44343 35.2632 6.59011 38.4099C9.73679 41.5566 13.7459 43.6995 18.1105 44.5677C22.475 45.4358 26.999 44.9903 31.1104 43.2873C35.2217 41.5843 38.7357 38.7004 41.2081 35.0003C43.6804 31.3002 45 26.9501 45 22.5C45 19.5453 44.418 16.6194 43.2873 13.8896C42.1566 11.1598 40.4992 8.67941 38.4099 6.5901C36.3206 4.50078 33.8402 2.84344 31.1104 1.71271C28.3806 0.58198 25.4547 0 22.5 0ZM22.5 40.5C18.9399 40.5 15.4598 39.4443 12.4997 37.4664C9.53966 35.4886 7.23255 32.6774 5.87018 29.3883C4.5078 26.0992 4.15134 22.48 4.84587 18.9884C5.54041 15.4967 7.25474 12.2894 9.77209 9.77208C12.2894 7.25473 15.4967 5.5404 18.9884 4.84586C22.48 4.15133 26.0992 4.50779 29.3883 5.87017C32.6774 7.23254 35.4886 9.53965 37.4665 12.4997C39.4443 15.4598 40.5 18.9399 40.5 22.5C40.5 27.2739 38.6036 31.8523 35.2279 35.2279C31.8523 38.6036 27.2739 40.5 22.5 40.5ZM31.5 20.25H13.5C12.9033 20.25 12.331 20.487 11.909 20.909C11.4871 21.331 11.25 21.9033 11.25 22.5C11.25 23.0967 11.4871 23.669 11.909 24.091C12.331 24.5129 12.9033 24.75 13.5 24.75H31.5C32.0967 24.75 32.669 24.5129 33.091 24.091C33.513 23.669 33.75 23.0967 33.75 22.5C33.75 21.9033 33.513 21.331 33.091 20.909C32.669 20.487 32.0967 20.25 31.5 20.25Z" fill="darkred" />
                </svg> : <svg width="20" height="20" viewBox="0 0 45 45" fill="red" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.5 3.75C18.7916 3.75 15.1665 4.84967 12.0831 6.90994C8.99964 8.97022 6.59641 11.8986 5.17727 15.3247C3.75812 18.7508 3.38681 22.5208 4.11028 26.1579C4.83376 29.7951 6.61952 33.136 9.24175 35.7583C11.864 38.3805 15.2049 40.1663 18.8421 40.8897C22.4792 41.6132 26.2492 41.2419 29.6753 39.8227C33.1014 38.4036 36.0298 36.0004 38.09 32.9169C40.1503 29.8335 41.25 26.2084 41.25 22.5C41.25 20.0377 40.765 17.5995 39.8227 15.3247C38.8805 13.0498 37.4993 10.9828 35.7582 9.24175C34.0171 7.50065 31.9502 6.11953 29.6753 5.17726C27.4005 4.23498 24.9623 3.75 22.5 3.75ZM22.5 37.5C19.5333 37.5 16.6332 36.6203 14.1664 34.972C11.6997 33.3238 9.77713 30.9811 8.64181 28.2403C7.5065 25.4994 7.20945 22.4834 7.78823 19.5736C8.36701 16.6639 9.79562 13.9912 11.8934 11.8934C13.9912 9.79561 16.6639 8.367 19.5736 7.78822C22.4834 7.20944 25.4994 7.50649 28.2402 8.64181C30.9811 9.77712 33.3238 11.6997 34.972 14.1664C36.6203 16.6332 37.5 19.5333 37.5 22.5C37.5 26.4782 35.9196 30.2936 33.1066 33.1066C30.2936 35.9196 26.4782 37.5 22.5 37.5Z" fill="darkred" />
                    <path d="M28.125 20.625H24.375V16.875C24.375 16.3777 24.1774 15.9008 23.8258 15.5492C23.4742 15.1975 22.9973 15 22.5 15C22.0027 15 21.5258 15.1975 21.1742 15.5492C20.8225 15.9008 20.625 16.3777 20.625 16.875V20.625H16.875C16.3777 20.625 15.9008 20.8225 15.5492 21.1742C15.1975 21.5258 15 22.0027 15 22.5C15 22.9973 15.1975 23.4742 15.5492 23.8258C15.9008 24.1774 16.3777 24.375 16.875 24.375H20.625V28.125C20.625 28.6223 20.8225 29.0992 21.1742 29.4508C21.5258 29.8024 22.0027 30 22.5 30C22.9973 30 23.4742 29.8024 23.8258 29.4508C24.1774 29.0992 24.375 28.6223 24.375 28.125V24.375H28.125C28.6223 24.375 29.0992 24.1774 29.4508 23.8258C29.8024 23.4742 30 22.9973 30 22.5C30 22.0027 29.8024 21.5258 29.4508 21.1742C29.0992 20.8225 28.6223 20.625 28.125 20.625Z" fill="darkred" />
                </svg>
                }</li></a>
            <div className="mobile-dropdown" style={showDD}>

                {
                    props.ddcontent.map((element, index) => (

                        <div className="dropdown-component" key={index} >
                            <h1 className="dropdown-heading text-center" key={index}>
                                {element.headings}
                            </h1>
                            <ul className='p-2'>
                                {
                                    element.content.map((content, index) => (
                                        <Link to={content.contentUrl} key={index}>
                                            <li>{content.contentTitle}</li>
                                            <span className="hover-line"></span>
                                        </Link>
                                    ))
                                }
                            </ul>
                            <div className="hover-background"></div>
                        </div>
                    ))
                }
            </div >
        </>
    )
}