import React from "react";

export default function AboutPrincipalRes() {
	return (
		<>
			<div className="principal-role">
				<h2>Role:</h2>
				<p>
					Principal is the academic, financial & administrative chief
					Executive of the institute.
				</p>
			</div>
			<div className="principal-role">
				<h2>Responsibilities: </h2>
				<ol>
					<li>
						Principal being a Head of Polytechnic is answerable to
						the Director, Technical Education (and to Chairman of
						Governing body in case of autonomous institutes) for all
						academic, financial and administrative/personnel
						activities of the institute.
					</li>
					<li>
						Responsible for Academic, financial administrative,
						Human Resources management of the institute.
					</li>
					<li>
						Admission authority for the institute to implement
						admission process as prescribed by state government/DTE
						time to time.
					</li>
					<li>
						Assessing the curriculum implementation processes
						(including Planning, scheduling, coordinating and
						monitoring) pertaining to various departments & CEP
						activities of the institute & take corrective actions if
						necessary.
					</li>
					<li>
						Assessing the requirements of the material, financial
						and human resources for effective institutional
						operations inclusive of academic & supporting
						operations.
					</li>
					<li>
						Advising & Facilitating the required resources for
						institute operations.
					</li>
					<li>
						Development & implementation of strategic plan for short
						term and long term development of the institute and
						sustainable quality improvement.
					</li>
					<li>
						To lead the accreditation activities of the institute
						for various quality standards.
					</li>
					<li>
						Plan & facilitate guidance & counseling and other
						students services at institute level.
					</li>
					<li>Maintaining support services, academic facilities.</li>
					<li>
						Responsible authority for financial controls,
						transactions, academic, personnel and security functions
						and to maintain necessary records financial, assets &
						academic records of the institute in stipulated formats.
					</li>
					<li>
						To facilitate, plan & organize faculty and supporting
						staff development programs.
					</li>
					<li>
						Promoting interactions with all stakeholders
						(Industries, MSBTE, DTE, RO, RBTE, Apprenticeship board,
						AICTE etc), facilitating students placements and
						students development programs.
					</li>
					<li>
						Provide motivation & guidance to faculty and other staff
						in the institute.
					</li>
					<li>
						Participate, motivate, guide & facilitate professional
						development through continuing education, training,
						testing, consultancy and industry-sponsored projects,
						entrepreneurship development, research.
					</li>
					<li>
						To act as a chief officer in charge for examinations.
					</li>
					<li>To facilitate industry interaction.</li>
					<li>
						To plan and implement the activities to take care of
						hygiene, safety and housekeeping in the institute.
					</li>
					<li>
						Take teaching load prescribed as per the norms issued
						time to time by the state government.
					</li>
					<li>
						Evaluate the performance of the faculty and supporting
						staff.
					</li>
					<li>
						Create, maintain and motivate cordial relations and team
						spirit in the institute & provide impartial & equal
						opportunities for contribution to faculty & staff.
					</li>
					<li>
						Keep abreast of the newer knowledge, skills and
						technology through self-up-gradation and dissemination
						of knowledge through articles, books, journals and
						seminars etc.
					</li>
					<li>
						Self-development through qualification improvement,
						enrichment, professional activities and interactions
						with bodies.
					</li>
					<li>
						Participate in non-formal mode of education for the
						benefit of society / Community.
					</li>
					<li>
						Implementation of Right to information (RTI) in the
						institute.
					</li>
					<li>
						To plan and implement the activities to take care of
						hygiene, safety and house-keeping in the institute.
					</li>
					<li>Upliftment of Institution's image in society.</li>
					<li>
						Implement various government schemes in the institute.
					</li>
				</ol>
			</div>
		</>
	);
}
