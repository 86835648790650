import React from "react";

export default function CooperativeStore() {
	return (
		<>
			<h1 className="heading">Student Co-operative Store</h1>
			<p>
				Keeping in mind that students get all required stationary and
				learning material under one roof, at reasonable rates, the
				cooperative store runs within the campus. The store is
				registered unit with cooperative Dept of Government of
				Maharashtra. The material like stationary, journal files,
				calculators, drawing sheets, laboratory manuals etc. are
				available in the store. For Store related enquiry contact : Mr.
				Ajay Dahane Mob: 9604585772.
			</p>
		</>
	);
}
